
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import Navagation from "@/components/commons/Navagation.vue";
import ListItem from "@/components/commons/list/ListItems.vue";
import Options from "@/components/commons/Options.vue";
import HeadImg from "@/components/commons/HeadImg.vue";
import Loading from "@/components/commons/Loading.vue";

import sort from "@/assets/nfts/sort.webp";
import upGray from "@/assets/nfts/upGray.webp";
import downGray from "@/assets/nfts/downGray.webp";
import desktopGray from "@/assets/logos/desktopGray.webp";
import discordGray from "@/assets/logos/discordGray.webp";
import telegramGray from "@/assets/logos/telegramGray.webp";
import twitterGray from "@/assets/logos/twitterGray.webp";

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { getCollectionDetail } from "@/model/nftLanding";
import { kmbNumber } from "@/utils/formatNumber";
import { useLog } from "@/utils/log";
import { getQueryString, getQueriesFromUrl } from "@/utils/url";

export default defineComponent({
  name: "CollectionDetail",
  components: {
    Navagation,
    ListItem,
    HeadImg,
    Options,
    Loading,
  },
  setup() {
    const { handleClickLog } = useLog();
    const sendClickLog = (origin = "") => {
      handleClickLog({
        event_type: "ui_click",
        origin,
        category: "collection_detail",
        news_type: "",
        request_id: "",
        news_entry_id: "",
      });
    };
    const { t } = useI18n();
    const currentOption = [
      {
        name: `${t("Current Price")}: ${t("high to low")}`,
        id: "curPrice_desc",
        icon: "down",
      },
      {
        name: `${t("Current Price")}: ${t("low to high")}`,
        id: "curPrice_asc",
        icon: "up",
      },
    ];
    const pageNum = ref(0);
    const pageSize = ref(12);
    const showOptions = ref(false);
    const checked = ref(currentOption[0]);
    const route = useRoute();
    const state: any = ref({});
    const dataList = ref([]);
    const loading = ref(true);
    const loadNext = ref();
    let isFirst = true;
    const showLoadMore = ref(true);
    let { typeIndex = "0" } = getQueriesFromUrl(location.href);
    const getData = () => {
      getCollectionDetail({
        orderBy: checked.value.id,
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        contract: route?.params?.contract,
      }).then((res) => {
        if (res?.nextPageNum === -1) {
          showLoadMore.value = false;
        } else {
          pageNum.value += 1;
        }
        if (isFirst) {
          state.value = res?.data || {};
          isFirst = false;
        }
        loading.value = false;
        dataList.value = dataList.value.concat(res?.data?.items || []);
      });
    };
    getData();
    const handleRight = () => {
      showOptions.value = true;
    };
    const resetParams = () => {
      dataList.value = [];
      pageNum.value = 0;
      loading.value = true;
    };
    watch(checked, () => {
      resetParams();
      getData();
    });

    const scrollTop = ref(0);
    let ob: any;
    onMounted(() => {
      window.addEventListener("scroll", function () {
        scrollTop.value =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      });

      ob = new IntersectionObserver(
        (entries) => {
          // When intersectionRatio is equal to 0, it means that the observed element does not enter the view.
          if (entries[0].intersectionRatio === 0) return;
          getData();
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(loadNext.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });

    return {
      upGray,
      downGray,
      sort,
      desktopGray,
      discordGray,
      telegramGray,
      twitterGray,
      scrollTop,

      state,
      dataList,
      loading,
      loadNext,
      showOptions,
      currentOption,
      checked,
      handleRight,
      kmbNumber,
      sendClickLog,
      getQueryString,
      typeIndex,
      showLoadMore,
    };
  },
});
