
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import up from "@/assets/nfts/upWhite.webp";
import down from "@/assets/nfts/downWhite.webp";
import check from "@/assets/nfts/check.webp";
export default defineComponent({
  name: "Options",
  props: {
    showOptionKey: {
      type: String,
      default: "name",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checked: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const scrollTop = ref(0);
    const handleChange = (item: any, index: number) => {
      emit("update:checked", item);
    };
    onMounted(() => {
      scrollTop.value =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      document.body.style.cssText += `width: 100%; position: fixed; overflow: hidden; top: -${scrollTop.value}px`;
    });
    onBeforeUnmount(() => {
      document.body.style.position = "";
      document.body.style.overflow = "auto";
      document.body.scrollTop = document.documentElement.scrollTop =
        scrollTop.value;
      document.body.style.top = "";
    });

    return {
      up,
      down,
      check,
      handleChange,
    };
  },
});
